body{
  margin:0px;
  padding: 0px;
  
  background-color: whitesmoke;
  font-family: 'Montserrat', sans-serif;
}
*{
  box-sizing: border-box;
}
ul{
  list-style: none;
}

.nav-color {
  background-color: rgb(231, 39, 39);
}


a{
  text-decoration: none;
}
button{
  outline: none;
  border: none;
}
input{
  outline: none;
  border: none;
}
.logo img{
  width: 90px;
}


#main {
 width: 100%;
 height: 850px;
 position: relative;
 background-repeat: no-repeat;
 background-size: cover;
 background-image: url("images/background.png");

 &::before {
 content: "";
position: absolute;
 top: 0;
 left: 0;
 width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5); 
 }
}

#main h1 {
 font-size: 55px;
 margin-bottom: 100px;
 color: white;
}
#main p {
 font-size: 18px;
 color: white; 
}

nav{
display: flex;
justify-content: space-around;
align-items: center;
box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.336);
position: fixed;
left: 0;
top: 0;
width:100%;
z-index: 1;
background-color: #b465a0
}
nav ul{
display: flex;
}
.active{
  background-color: #d0ff00;
  color:#993a3a;
box-shadow: 5px 10px 30px rgba(189, 198, 64, 0.411);
transition: all ease 0.2s;
}

nav ul li a{
  font-family: calibri;
height:40px;
line-height: 43px;
margin: 3px;
padding: 0px 22px;
display: flex;
font-size: 0.8rem;
text-transform: uppercase;
font-weight: 500;
color:#ffffff;
  letter-spacing: 1px;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}
.hey{
  font-family: calibri;
color:#ffffff;
font-weight: 500;
font-size: 0.9rem;
border-bottom: 2px solid #000266;
}
nav ul li a:hover{
background-color: #d0ff00;
  color:#131212;
box-shadow: 5px 10px 30px rgba(189, 198, 64, 0.411);
transition: all ease 0.2s;
}
nav .menu-btn,
.menu-icon{
  display:none;
}
.name{
font-family: 'Montserrat';
width: 500px;
position: absolute;
  left: 50%;
  text-align: center;
top: 50%;
transform: translate(-50%,-50%);
}
.name span{
  color: #d0ff00;
  border-bottom: 2px solid #d0ff00;
}
.name .details{

  color: antiquewhite;
  font-style: italic;
  font-weight:bolder;
  font-size: 20px;
}
.name h1{

font-family: var(--font-family-Cinzel);
font-size: 60px;
margin:0px;
letter-spacing: 3px;
color:rgb(80 0 63);
font-style: italic;
}
.header-btns{
  display: flex;
  margin-top: 40px;
  margin-left: 6.8em;
}
.cv-btn{
width:110px;
height: 40px;
display: flex;
justify-content: center;
  align-items: center;
  background-color: #d0ff00;
  box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.137);
  border-radius: 10px;
color:#000266;
}
.cv-btn1{
  font-size: 14px;
width:140px;
height: 40px;
display: flex;
justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid #ffffff;
  color:#ffffff;
  border-radius: 10px;
  margin-left: 20px;
}
.cv-btn:hover{
background-color: #ffffff;
  transition: all ease 0.5s;
  color: #000266;
}
.cv-btn1:hover{
background-color: #ffffff;
  transition: all ease 0.5s;
  color: #000266;
}

.arrow{
  align-self: end;
  width: 50%;
  height: 15%;
  margin-bottom: 4em;
  position: absolute;
  bottom:0px;
  border-right: 2px solid  #d0ff00;
  animation: arrow-animation ease 1.5s;

}
.arrow::after{
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 11px 0px 11px;
  border-color: #d0ff00 transparent transparent transparent;
  right: -0.7em;
  bottom: -2px;
}
@keyframes arrow-animation{
  0%{
      bottom: 70px;
      opacity: 0.2;
  }
  100%{
      bottom: 5px;
      opacity: 1;
  }
}
.f-heading h1{
  color:rgb(80 0 63);
  font-size: 2.5rem;
  margin: -40px;
  padding: 91px;
  font-style: italic;
  font-family: cursive;

}
.f-heading p{
  margin-bottom: -920px;
  color:rgba(247, 247, 247, 0.678);
  font-size: 1rem;
  margin-top: 10px;
  padding: 0px;
}
.f-heading{
  text-align: center;
  margin-top: 60px;
  margin-bottom: -120px;
}
#features{
    margin-top:140px ;
width:100%;
height: 120vh;
background-color:#000000;
box-sizing: border-box;
display: flex;
align-items: center;
justify-content: space-between;
padding: 50px 5% 0px 5%;
position: relative;
}

.features-text{
  width: 45%;
  color: #fff;
  list-style: circle;
}
.features-text h2{
font-size: 2rem;
color:#d0ff00;
font-weight: 400;
}
.features-text h2{
margin: 0px;
padding: 0px;
}
.features-text p{
font-size: 1.2rem;
color:#b4b4b4;

}
.features-text button{
  margin-top: 20px;
width: 140px;
height: 40px;
border-radius: 10px;
border: none;
  outline: none;
  color: #000266;
background-color:#d0ff00;
}
.features-text button:hover{
background-color: #ffffff;
  transition: all ease 0.3s;
  color: #000266;
}
.features-model{
  width: 50%;
}
.features-model img{
width: 500px;
}

#services{
  margin-top:50px;
  width:100%;
  height:100vh;
  box-sizing: border-box;
  font-family: calibri;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px ;
  padding-bottom: 650px ;
}

.s-heading h1{
  color:rgb(80 0 63);
  font-size: 3rem;
  margin: 0px;
  padding: 0px;
  font-style: italic;
  font-family: cursive;

}




.s-heading h2{
  color: rgb(80 0 63);
    font-size: 2.5rem;
    font-style: italic;
    font-family: cursive;;

}


.s-heading p{
  font-size: 1.2rem;
  margin: 0px;
  padding: 0px;
  font-family: Cinzel;
  font-style: italic;
}
.s-heading{
  text-align: center;
  margin: 40px 0px;
  margin-top: 151px;
  text-align-last: inherit;
  text-transform: inherit;

}
.s-box{
  background-color: #ffffff;
  width:200px;
  height: 250px;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 12px rgba(0,0,0,0.1);
  position: relative;
}
.b-container{
  display: flex;
  justify-content: center;
  align-content: center;


}
.z-container{
  display: block;
 font-size: 1.2rem;
 font-family: Cinzel;


}


.ul-container{
  font-size: 1.2rem; 
  list-style-type: circle;
  color: #000000;
}
.s-b-img{
  width: 150%;
  height: 100%;

}
.s-b-img img{
  width:100%;
  height: 100%;

}
.s-b-text{
  width: 100%;
  height: 40%;
  background-color:#ffffff;
  display:flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.s-b-text p{
  margin: 0px;
  color:#000266a6;
  font-size: 1.1rem;
  font-family: calibri;
  display: block;
  display: -webkit-box;
  max-width: 80%;
  margin-top: 25px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.s-b-text p:hover{
  color:#000266;
}
.s-b-text a{
  margin-top:15px ;
}
.s-type{
  font-family: calibri;
  color:#000266;
  background-color: #ffffff;
  padding: 10px 15px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 2px 2px 12px rgba(0,0,0,0.2);
}
.s-box:hover{
  transform:translateY(-10px);
  transition: all ease 0.3s;

}
#feature1{
  margin-top: -220px;
  width:100%;
  height:100vh;
  box-sizing: border-box;
  font-family: calibri;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px ;
  padding-bottom: 250px;
}


.f-heading p{
  color: #000000;
  font-size: 1.2rem;
  margin: 0px;
  padding: 0px;
  font-family: Cinzel;
  font-style: italic;
}
.f-heading{
  text-align: center;
  margin: 20px 0px;
}
#subscribe{
  
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
  margin:10px 0px;
 
  padding: 30px 0px;
}
#subscribe h3{
font-size:2.5rem;
margin:20px;
color: #000000;
}
.subscribe-input{
  width: 600px;
  height: 80px;
  
background-color:#FFFFFF;
display:flex;
justify-content: center;
border-radius: 50px;
box-shadow: 2px 2px 30px rgba(0,0,0,0.15);
}
.subscribe-input input{
width:100%;
background-color: transparent;
border:none;
outline: none;
text-align: center;
color:#242425;
}
.subscribe-input a{
width:200px;
height:35px;
background-color:#d0ff00;
color:#000000;
display: flex;
font-size: 0.9rem;
justify-content: center;
align-items: center;
margin: auto 10px;
border-radius: 20px;
font-weight: 500;
transition: 0.4s ease-in;
}
.subscribe-input a:hover{
  background-color:#000000;
color:#d0ff00;
}
@media(max-width:1190px){
#main{
  background-size: 1150px !important;
  
}

.name{
  font-family: 'Montserrat';
  width: 500px;
  position: absolute;
    left: 50%;
    text-align: center;
  top: 50%;
  transform: translate(-50%,-50%);
}
.header-btns{
  margin-left: 10em;
}

.features-model img{
  height:400px;
  }
  #services{
  height:auto;
  }
  #feature1{
    height:auto;
    }
  .b-container{
  flex-wrap: wrap;
}
  .s-box{
  flex-grow: 1;
}
.s-b-img img{
  object-fit:cover;
}

}

@media(max-width:970px){
  #main{
    background-size: 970px !important;
  }
  #services{
    margin-bottom: -610px;
    margin-top: -96px;
  }
  #feature1{
    margin-top: -60px;
  }

.s-heading h1{
  font-size: 2.5rem;
  color: #d0ff00;
}
.features-text h2{
  font-weight: 600;
}
.b-container{
  margin-top: 30px;
}
#subscribe h3{
  font-size: 2.5rem;
}
#features{
  margin-top: -60px;
  justify-content: center;
  padding-top: 0px;
  font-size: 2rem;
  text-align: center;
}
.f-heading{
  display: none;
}
.features-text{
  width: 90%;
}
.features-text h1{
  font-size:4rem;
  }

}


   @media(max-width:990px) {
    .name {
      font-family: 'Montserrat';
      width: 500px;
      position: absolute;
      left: 50%;
      text-align: center;
      bottom: 70px; 
      transform: translate(-50%, -50%);
    }
    
    #main h1 {
      font-size: 12px;
      margin-bottom: 10px;
      color: white;
    }
  
    #main p {
      font-size: 5px;
      color: white;
      margin-top: 40px; 
    }
  
  #main{
    height: 200px;
    background-size: 500px !important;
    
  }
.name{
  width:60%;
  }}
  .scroll{
      left: 42.5%;
  }
@media(max-width:1100px){.menu-icon{
  display:block;}
      nav{
        background-color: white;
          justify-content: space-between;
          height: 65px;
          padding: 0px 30px;
      }
      .logo img{
          width:70px;
      }
      .header-btns{
          margin: 0;
      }
      .menu{
          display:none;
          position:absolute;
          top:65px;
          left:0px;
          background-color:#000000;
          border-bottom:4px solid #d0ff00;
          width:100%;
          padding:0px;
          margin:0px;
      }
      .menu li{
          width:100%;
      }
      nav .menu li a{
          width:100%;
          height:40px;
          justify-content: center;
          align-items: center;
          margin:0px;
          padding: 25px;
          border:1px solid rgba(158, 155, 0, 0.03);
      }
      nav .menu-icon{
          cursor:pointer;
          float:right;
          padding:28px 20px;
          position:relative;
          user-select: none;
      }
      nav .menu-icon .nav-icon{
          background-color:#d0ff00;
          position: absolute;
          display:block;
          height:2px;
          position:relative;
          transition: background 0.2s ease-out;
          width:18px;
      }
      nav .menu-icon .nav-icon:before,
      nav .menu-icon .nav-icon:after{
          background:#d0ff00;
          content:'';
          display:block;
          height:100%;
          position:absolute;
          transition:all ease-out 0.2s;
          width:100%;
      }
      nav .menu-icon .nav-icon:before{
          top:5px;
      }
      nav .menu-icon .nav-icon:after{
          top:-5px;
      }
      nav .menu-btn:checked ~ .menu-icon .nav-icon{
          background:transparent;
      }
      nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
          transform: rotate(-45deg);
          top:0;
      }
      nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
          transform: rotate(45deg);
          top:0;
      }
      nav .menu-btn{
          display:none;
      }
      nav .menu-btn:checked ~ .menu{
          display:block;
      }}
      @media(max-width:400px){
          .s-box{
              width: 100%;
              height:400px;
          }
      }
      @media(max-width:376px){
        .subscribe-input{
          width:300px;
          height:50px;
          }
          nav .menu-icon{
            position: absolute;
            margin-left: 34.5%;
        }
        }




        

     

       footer {
        margin-top: 31px;
        background-color: rgb(255 235 246);
        color: rgb(80 0 63);
        padding: 20px;
        display: flex;
        font-family: cursive;
        justify-content: space-around;
        
      }
      
      .footer-address,
      .footer-contact {
        width: 45%;
      }
      
      h3 {
        font-size: 1.2rem;
        margin-bottom: 10px;
      }
      
      p {
        font-size: 1rem;
        margin: 0;
      }
      
     
      svg {
        margin-right: 10px; 
      }
      


     
.s-heading {
  font-size: 18px; 
  padding: 20px;
}

.ul-container {
  font-size: 16px; 
  margin-bottom: 10px;
}


@media (max-width: 990px) {
  .s-heading {
    color:rgb(80 0 63);
    font-family: 'Montserrat';
    font-size: 16px; 
  }
  .s-heading p{
    font-size: .6rem;
    margin: 0px;
    padding: 0px;
    font-family: Cinzel;
    font-style: italic;
  }
  .ul-container {
    font-size: .6rem; 
  }
}







@media (max-width: 990px) {
  .f-heading h1{
    font-size: 5px; 
  }
  .f-heading1 h1{
    font-size: 5px;
  }

@media (min-width: 300px) and (max-width: 990px) {
  .hello {font-family: 'Montserrat';
   
    display: flex;
    color:rgb(80 0 63);
    justify-content: center;
    align-items: center; 
   
  }
}
@media (min-width: 300px) and (max-width: 990px) {
  .hello1 {
    font-style: italic;
   
    display: flex;
    font-family: Cinzel;
    font-style: italic;
    font-size: .6rem;
    margin: 0px;
    padding: 0px;
    justify-content: center; 
    align-items: center; 
  
  }
}

  .b-container {
    flex-direction: column;
  }
}




@media (max-width: 600px) {
  footer {
    margin-top: -290px;
    flex-direction: column;
    padding: 150px;
 
  }
  
  .footer-address,
  .footer-contact {
   margin-left: 10px;

  }
  
  svg {
    
    margin-left: -100px; 
  }
}

@media (min-width: 990px) {
  .hello,
  .hello1 {
    display: none;
  }
}



.f-heading {
  font-style: italic;
  font-size: 24px;
  padding: 20px;
}

.b-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


@media (max-width: 990px) {
  .f-heading {
    font-style: italic;
    font-size: 18px;
  }

  .b-container {
    flex-direction: column;
    align-items: center;
  }

}
